import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

// ROTAS DE CONTROLE
import Page404 from '../pages/Controle/Page404'

// ROTAS DA LOJA
import SiteMain from '../pages/Site/Main';
import SiteHome from '../pages/Site/Home';
import SiteCategoria from '../pages/Site/Categoria';
import SitePorCategoria from '../pages/Site/PorCategoria';
import SiteMarcas from '../pages/Site/Marcas';
import SiteSobre from '../pages/Site/Sobre';
import SiteExplorar from '../pages/Site/Explorar';

// ROTAS DO SISTEMA
import Login from '../pages/Autenticacao/Login';
import Register from '../pages/Autenticacao/Register';
import Dashboard from '../pages/Sistema/Dashboard/Dashboard';
import DashboardLoad from '../pages/Sistema/Dashboard/Load';
import Perfil from '../pages/Sistema/Perfil/Perfil';

// ROTAS CATEGORIAS
import CategoriasList from '../pages/Sistema/Categorias/List';
import CategoriasAdd from '../pages/Sistema/Categorias/Add';
import CategoriasEdit from '../pages/Sistema/Categorias/Edit';
import CategoriasTrash from '../pages/Sistema/Categorias/Trash';
// ROTAS EMPRESAS
import EmpresasList from '../pages/Sistema/Empresas/List';
import EmpresasAdd from '../pages/Sistema/Empresas/Add';
import EmpresasEdit from '../pages/Sistema/Empresas/Edit';
import EmpresasTrash from '../pages/Sistema/Empresas/Trash';
    // ROTAS EMPRESAS USUARIOS
    import EmpresasUsuariosList from '../pages/Sistema/Empresas/Usuarios/List';
    import EmpresasUsuariosAdd from '../pages/Sistema/Empresas/Usuarios/Add';
    import EmpresasUsuariosEdit from '../pages/Sistema/Empresas/Usuarios/Edit';
    import EmpresasUsuariosTrash from '../pages/Sistema/Empresas/Usuarios/Trash';
// ROTAS CLIENTES
import ClientesList from '../pages/Sistema/Clientes/List';
import ClientesAdd from '../pages/Sistema/Clientes/Add';
import ClientesEdit from '../pages/Sistema/Clientes/Edit';
import ClientesTrash from '../pages/Sistema/Clientes/Trash';
// ROTAS CUPONS
import CuponsList from '../pages/Sistema/Cupons/List';
import CuponsAdd from '../pages/Sistema/Cupons/Add';
import CuponsEdit from '../pages/Sistema/Cupons/Edit';
import CuponsGalery from '../pages/Sistema/Cupons/Galery';
import CuponsTrash from '../pages/Sistema/Cupons/Trash';
// ROTAS USUARIOS
import UsuariosList from '../pages/Sistema/Usuarios/List';
import UsuariosAdd from '../pages/Sistema/Usuarios/Add';
import UsuariosEdit from '../pages/Sistema/Usuarios/Edit';
import UsuariosTrash from '../pages/Sistema/Usuarios/Trash';
// ROTAS HISTORICO
import HistoricoList from '../pages/Sistema/Historico/List';
// ROTAS MINHA LOJA
import MinhaLojaList from '../pages/Sistema/MinhaLoja/MinhaLoja';

class AppStack extends React.Component {

    render = () => {

        return (
            <BrowserRouter>
                <Routes>
                    {/* LOJA */}
                    {/* <Route path="" element={<SiteMain />}>
                        <Route path="" element={<SiteHome />} />
                        <Route path="explorar" element={<SiteExplorar />} />
                        <Route path="categorias" element={<SiteCategoria />} />
                        <Route path="categorias/:id" element={<SitePorCategoria />} />
                        <Route path="parceiros" element={<SiteMarcas />} />
                        <Route path="sobre" element={<SiteSobre />} />
                        <Route path="contato" element={null} />
                    </Route> */}
                    {/* SISTEMA */}
                    <Route path="" element={<Login />}/>
                    <Route path="cadastrar" element={<Register />}/>
                    <Route path="dashboard" element={<Dashboard />}>
                        <Route path="" element={<DashboardLoad />}/>
                        <Route path="perfil" element={<Perfil />}/>
                        <Route path="parceiros" element={<Outlet />}>
                            <Route path="" element={<EmpresasList />} />
                            <Route path=":id/edit" element={<EmpresasEdit />} />
                            <Route path=":id/usuarios" element={<Outlet />}>
                                <Route path="" element={<EmpresasUsuariosList />} />
                                <Route path=":id/edit" element={<EmpresasUsuariosEdit />} />
                                <Route path="add" element={<EmpresasUsuariosAdd />} />
                                <Route path="trash" element={<EmpresasUsuariosTrash />} />
                            </Route>
                            <Route path="add" element={<EmpresasAdd />} />
                            <Route path="trash" element={<EmpresasTrash />} />
                        </Route>
                        <Route path="categorias" element={<Outlet />}>
                            <Route path="" element={<CategoriasList />} />
                            <Route path=":id/edit" element={<CategoriasEdit />} />
                            <Route path="add" element={<CategoriasAdd />} />
                            <Route path="trash" element={<CategoriasTrash />} />
                        </Route>
                        <Route path="clientes" element={<Outlet />}>
                            <Route path="" element={<ClientesList />} />
                            <Route path=":id/edit" element={<ClientesEdit />} />
                            <Route path="add" element={<ClientesAdd />} />
                            <Route path="trash" element={<ClientesTrash />} />
                        </Route>
                        <Route path="usuarios" element={<Outlet />}>
                            <Route path="" element={<UsuariosList />} />
                            <Route path=":id/edit" element={<UsuariosEdit />} />
                            <Route path="add" element={<UsuariosAdd />} />
                            <Route path="trash" element={<UsuariosTrash />} />
                        </Route>
                        <Route path="historico" element={<Outlet />}>
                            <Route path="" element={<HistoricoList />} />
                        </Route>
                        <Route path="minhaloja" element={<Outlet />}>
                            <Route path="" element={<MinhaLojaList />} />
                        </Route>
                        <Route path="cupons" element={<Outlet />}>
                            <Route path="" element={<CuponsList />} />
                            <Route path=":id/edit" element={<CuponsEdit />} />
                            <Route path=":id/galeria" element={<CuponsGalery />} />
                            <Route path="add" element={<CuponsAdd />} />
                            <Route path="trash" element={<CuponsTrash />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </BrowserRouter>
        )

    }

}

export default AppStack;