import React from "react";
import { Button, Col, Layout, Row } from 'antd';
import { Link } from "react-router-dom";

import { IoCubeOutline, IoBusinessOutline, IoFileTrayStackedOutline, IoFolderOpenOutline, IoPeopleOutline, IoLogoElectron, IoLockClosedOutline, IoPinOutline, IoPricetagsOutline, IoPulseOutline, IoSparklesOutline, IoBoatOutline, IoClipboardOutline, IoStorefrontOutline, IoStarOutline, IoEaselOutline, IoCopyOutline, IoChatboxOutline, IoSettingsOutline, IoBookOutline } from 'react-icons/io5';
import { verifyConfig } from "../services/server-api";

class Screen extends React.Component {

    state = { select: 'dashboard' }

    componentDidMount = () => {
        
        const params = window.location.href;
        const values = params.split('/');

        values[4] = values[4] ? values[4] : values[3]
        
        this.onSelect(values[4])

    }

    onSelect = (page) => {
        this.setState({select: page})
    }

    render = () => {

        const { Content } = Layout
        const menus = [
            { label: 'Dashboard', url: '/dashboard', value: 'dashboard', icon: <IoCubeOutline size={22}/>, permission: null },
            { label: 'Minha Loja', url: 'minhaloja', value: 'minhaloja', icon: <IoCubeOutline size={22}/>, permission: null },
            { label: 'Parceiros', url: 'parceiros', value: 'parceiros', icon: <IoCubeOutline size={22}/>, permission: '1' },
            { label: 'Clientes', url: 'clientes', value: 'clientes', icon: <IoCubeOutline size={22}/>, permission: '1' },
            { label: 'Categorias', url: 'categorias', value: 'categorias', icon: <IoCubeOutline size={22}/>, permission: '1' },
            { label: 'Cupons', url: 'cupons', value: 'cupons', icon: <IoCubeOutline size={22}/>, permission: '2' },
            { label: 'Usuários', url: 'usuarios', value: 'usuarios', icon: <IoCubeOutline size={22}/>, permission: null },
            // { label: 'Suporte', url: 'suporte', value: 'suporte', icon: <IoCubeOutline size={22}/>, permission: null },
            { label: 'Histórico', url: 'historico', value: 'historico', icon: <IoCubeOutline size={22}/>, permission: '1' },
        ]

        return (
            <Content style={{marginTop: 15}}>
                <Row gutter={[4,4]}>
                    {menus.map((v, i) => {

                        if (v.permission === null || verifyConfig(v.permission)) {
                            return (
                                <Col span={24} className="sys_sidebar__col_item" key={i}>
                                    <Link to={v.url}>
                                        <Button onClick={() => this.onSelect(v.value)} type="link" block className={this.state.select === v.value ? "sys_sidebar__col_button active" : "sys_sidebar__col_button"}>
                                            {v.icon} {v.label} 
                                        </Button>
                                    </Link>
                                </Col>
                            )
                        }
                        return null;
                        
                    })}
                </Row>
            </Content>
        )
    }

}

export default Screen;